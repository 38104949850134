<template>
    <b-row>
        <b-col cols="12">
            <div class="d-flex justify-content-between align-items-center mb-4">
                <feather
                    type="arrow-left-circle"
                    v-b-popover.hover.top="'Presione esc'"
                    role="button"
                    class="text-secondary"
                    size="2rem"
                    @click="back()"
                ></feather>
                <div class="d-flex flex-row">
                    <b-button
                        variant="secondary"
                        :disabled="isLoading"
                        v-b-popover.hover.top="'Presione esc'"
                        size="md"
                        class="mr-4"
                        @click="back()"
                    >
                        Cancelar
                    </b-button>

                    <b-button
                        variant="primary"
                        :disabled="isLoading || read_only"
                        size="md"
                        v-b-popover.hover.top="
                            'Presione ctrl+shift+s o cmd+shift+s'
                        "
                        class="d-flex align-items-center"
                        @click="save"
                    >
                        <feather
                            type="save"
                            size="1rem"
                            class="mr-2 text-blue-active"
                        />
                        <span
                            v-if="isLoading"
                            class="d-sm-flex align-items-center justify-content-center"
                        >
                            <b-spinner small></b-spinner>
                            <span class="ml-2">Guardando...</span>
                        </span>
                        <span v-else>Guardar</span>
                    </b-button>
                </div>
            </div>
        </b-col>
        <b-col
            cols="12"
            lg="12"
            class="d-flex align-items-stretch"
            style="min-height: 70vh"
            v-hotkey="keymap"
        >
            <b-card class="mb-4 w-100">
                <div>
                    <b-form class="p-5">
                        <!-- title="Info" -->
                        <div class="d-flex mb-5">
                            <div>
                                <h3 class="mb-2">Datos de la empresa</h3>
                                <div class="text-gray mb-5">
                                    Por favor registre los datos de su empresa
                                    para continuar
                                </div>
                            </div>
                            <div class="d-none d-sm-block ml-auto">
                                <div style="position: relative;">
                                    <b-img
                                        :src="empresa.img"
                                        width="70"
                                        rounded="circle"
                                        role="button"
                                        @click="read_only ? null : openImgPicker()"
                                    />
                                    <feather
                                        v-if="empresa.img !== defaultImg"
                                        style="cursor: pointer; position: absolute; bottom: 0px; left: 3.3rem;"
                                        type="x-circle"
                                        size="1.5rem"
                                        class="text-error bg-white"
                                        @click="() => {
                                            empresa.img = defaultImg
                                            $refs.empresa_img_input.value = null
                                        }"
                                    ></feather>
                                </div>
                            </div>
                        </div>
                        <div
                            class="d-flex justify-content-center mb-4 d-sm-none"
                        >
                            <div style="position: relative;">
                                <b-img
                                    :src="empresa.img"
                                    width="70"
                                    rounded="circle"
                                    role="button"
                                    @click="read_only ? null : openImgPicker()"
                                />
                                <feather
                                    v-if="empresa.img !== defaultImg"
                                    style="cursor: pointer; position: absolute; bottom: 0px; left: 3.3rem;"
                                    type="x-circle"
                                    size="1.5rem"
                                    class="text-error bg-white"
                                    @click="() => {
                                        empresa.img = defaultImg
                                        $refs.empresa_img_input.value = null
                                    }"
                                ></feather>
                            </div>
                        </div>
                        <input
                            type="file"
                            ref="empresa_img_input"
                            style="display: none"
                            @change="onFileChanged"
                        />
                        <b-row>
                            <b-col cols="12" md="3" lg="3" xl="3">
                                <b-form-group>
                                    <label for="tipo_empresa_id"
                                        >Tipo empresa
                                        <small style="color: red"
                                            >*</small
                                        ></label
                                    >
                                    <jautocomplete
                                        id="tipo_empresa_id"
                                        :items="tipos_empresa"
                                        item-text="descripcion"
                                        item-value="id"
                                        @change="setTipoDocumento"
                                        :disabled="read_only"
                                        open-on-focus
                                        v-model="
                                            $v.empresa.tipo_empresa_id.$model
                                        "
                                        :state="
                                            validateState('tipo_empresa_id')
                                        "
                                    />
                                    <!-- @keyup.once.enter="
                                            () => {
                                                if (
                                                    $v.empresa.tipo_empresa_id
                                                        .$model
                                                ) {
                                                    setTipoDocumento(
                                                        $v.empresa
                                                            .tipo_empresa_id
                                                            .$model
                                                    )
                                                }
                                                goToNextField(
                                                    $v.empresa.tipo_empresa_id
                                                        .$model === 2 &&
                                                        $v.empresa
                                                            .tipo_empresa_id
                                                            .$model
                                                        ? 'numero_documento'
                                                        : 'tipo_documento_id'
                                                )
                                            }
                                        "
                                        @keyup.once.tab="
                                            goToNextField('tipo_documento_id')
                                        " -->

                                    <!-- <b-form-select
                                        id="tipo_empresa_id"
                                        :options="tipos_empresa"
                                        text-field="descripcion"
                                        value-field="id"
                                        v-model="
                                            $v.empresa.tipo_empresa_id.$model
                                        "
                                        @change="setTipoDocumento"
                                        :state="
                                            validateState('tipo_empresa_id')
                                        "
                                        :disabled="read_only"
                                    >
                                        <template #first>
                                            <b-form-select-option
                                                :value="null"
                                                disabled
                                                >Seleccione</b-form-select-option
                                            >
                                        </template>
                                    </b-form-select> -->
                                    <div
                                        class="invalid-field mt-1"
                                        v-if="$v.empresa.tipo_empresa_id.$error"
                                    >
                                        Campo requerido
                                    </div>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" md="4" lg="4" xl="4">
                                <b-form-group>
                                    <label for="tipo_documento_id"
                                        >Tipo documento
                                        <small style="color: red"
                                            >*</small
                                        ></label
                                    >
                                    <jautocomplete
                                        id="tipo_documento_id"
                                        :items="tipos_documento"
                                        item-text="descripcion"
                                        item-value="id"
                                        :disabled="
                                            disableTipoDocumento || read_only
                                        "
                                        open-on-focus
                                        v-model="
                                            $v.empresa.tipo_documento_id.$model
                                        "
                                        :state="
                                            validateState('tipo_documento_id')
                                        "
                                    />
                                    <!-- @keyup.once.enter="
                                            goToNextField('numero_documento')
                                        "
                                        @keyup.once.tab="
                                            goToNextField('numero_documento')
                                        " -->

                                    <!-- <b-form-select
                                        id="tipo_documento_id"
                                        :options="tipos_documento"
                                        text-field="descripcion"
                                        value-field="id"
                                        :disabled="
                                            disableTipoDocumento || read_only
                                        "
                                        v-model="
                                            $v.empresa.tipo_documento_id.$model
                                        "
                                        :state="
                                            validateState('tipo_documento_id')
                                        "
                                    >
                                        <template #first>
                                            <b-form-select-option
                                                :value="null"
                                                disabled
                                                >Seleccione</b-form-select-option
                                            >
                                        </template>
                                    </b-form-select> -->
                                    <div
                                        class="invalid-field mt-1"
                                        v-if="
                                            $v.empresa.tipo_documento_id.$error
                                        "
                                    >
                                        Campo requerido
                                    </div>
                                </b-form-group>
                            </b-col>
                            <b-col cols="10" md="4" lg="4" xl="4">
                                <b-form-group>
                                    <label for="numero_documento"
                                        >Número documento
                                        <small style="color: red"
                                            >*</small
                                        ></label
                                    >
                                    <b-form-input
                                        id="numero_documento"
                                        type="number"
                                        placeholder="Ej: 26186920835"
                                        @focus="onFocused"
                                        v-model="
                                            $v.empresa.numero_documento.$model
                                        "
                                        @input="setDV"
                                        :state="
                                            validateState('numero_documento')
                                        "
                                        :disabled="read_only"
                                    >
                                        <!-- @keyup.enter="
                                            goToNextField('razon_social')
                                        "
                                        @keyup.tab="
                                            goToNextField('razon_social')
                                        " -->
                                    </b-form-input>
                                    <div
                                        class="length-field mt-1"
                                        v-if="
                                            !$v.empresa.numero_documento
                                                .$anyError
                                        "
                                    >
                                        ({{
                                            $v.empresa.numero_documento.$model
                                                .length
                                        }}
                                        /
                                        {{
                                            $v.empresa.numero_documento.$params
                                                .maxLength.max
                                        }})
                                    </div>
                                    <b-form-invalid-feedback
                                        v-if="
                                            !$v.empresa.numero_documento
                                                .minLength
                                        "
                                    >
                                        Este campo debe tener al menos
                                        {{
                                            $v.empresa.numero_documento.$params
                                                .minLength.min
                                        }}
                                        caracteres
                                    </b-form-invalid-feedback>
                                    <b-form-invalid-feedback
                                        v-if="
                                            !$v.empresa.numero_documento
                                                .maxLength
                                        "
                                    >
                                        Este campo debe tener máximo
                                        {{
                                            $v.empresa.numero_documento.$params
                                                .maxLength.max
                                        }}
                                        caracteres
                                    </b-form-invalid-feedback>
                                    <b-form-invalid-feedback
                                        v-if="
                                            !$v.empresa.numero_documento
                                                .required
                                        "
                                    >
                                        Campo requerido
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                            <b-col cols="2" md="1" lg="1" xl="1">
                                <b-form-group>
                                    <label for="digito_verificacion">DV</label>
                                    <b-form-input
                                        id="digito_verificacion"
                                        type="text"
                                        disabled
                                        v-model="
                                            $v.empresa.digito_verificacion
                                                .$model
                                        "
                                    ></b-form-input>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row class="mt-md-4">
                            <b-col cols="12" md="9" lg="9" xl="9">
                                <b-form-group>
                                    <label for="razon_social"
                                        >Razón social
                                        <small style="color: red"
                                            >*</small
                                        ></label
                                    >
                                    <b-form-input
                                        id="razon_social"
                                        type="text"
                                        @focus="onFocused"
                                        placeholder="Ej: Mi empresa S.A.S"
                                        v-model="$v.empresa.razon_social.$model"
                                        :state="validateState('razon_social')"
                                        :disabled="read_only"
                                    ></b-form-input>
                                    <!-- @keyup.enter="goToNextField('telefono')"
                                        @keyup.tab="goToNextField('telefono')" -->
                                    <div
                                        class="length-field mt-1"
                                        v-if="
                                            !$v.empresa.razon_social.$anyError
                                        "
                                    >
                                        ({{
                                            $v.empresa.razon_social.$model
                                                .length
                                        }}
                                        /
                                        {{
                                            $v.empresa.razon_social.$params
                                                .maxLength.max
                                        }})
                                    </div>
                                    <b-form-invalid-feedback
                                        v-if="
                                            !$v.empresa.razon_social.maxLength
                                        "
                                    >
                                        Este campo debe tener máximo
                                        {{
                                            $v.empresa.razon_social.$params
                                                .maxLength.max
                                        }}
                                        caracteres
                                    </b-form-invalid-feedback>
                                    <b-form-invalid-feedback
                                        v-if="!$v.empresa.razon_social.required"
                                    >
                                        Campo requerido
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" md="3" lg="3" xl="3">
                                <b-form-group>
                                    <label for="telefono"
                                        >Teléfono
                                        <small style="color: red"
                                            >*</small
                                        ></label
                                    >
                                    <b-form-input
                                        id="telefono"
                                        type="number"
                                        placeholder="Ej: 7852920"
                                        @focus="onFocused"
                                        v-model="$v.empresa.telefono.$model"
                                        :state="validateState('telefono')"
                                        :disabled="read_only"
                                    ></b-form-input>
                                    <!-- @keyup.enter="
                                            goToNextField(
                                                'codigo_actividad_economica_id'
                                            )
                                        "
                                        @keyup.tab="
                                            goToNextField(
                                                'codigo_actividad_economica_id'
                                            )
                                        " -->
                                    <div
                                        class="length-field mt-1"
                                        v-if="!$v.empresa.telefono.$anyError"
                                    >
                                        ({{ $v.empresa.telefono.$model.length }}
                                        /
                                        {{
                                            $v.empresa.telefono.$params
                                                .maxLength.max
                                        }})
                                    </div>
                                    <b-form-invalid-feedback
                                        v-if="!$v.empresa.telefono.minLength"
                                    >
                                        Este campo debe tener al menos
                                        {{
                                            $v.empresa.telefono.$params
                                                .minLength.min
                                        }}
                                        caracteres
                                    </b-form-invalid-feedback>
                                    <b-form-invalid-feedback
                                        v-if="!$v.empresa.telefono.maxLength"
                                    >
                                        Este campo debe tener máximo
                                        {{
                                            $v.empresa.telefono.$params
                                                .maxLength.max
                                        }}
                                        caracteres
                                    </b-form-invalid-feedback>
                                    <b-form-invalid-feedback
                                        v-if="!$v.empresa.telefono.required"
                                    >
                                        Campo requerido
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row class="mt-md-4">
                            <b-col cols="12" md="4">
                                <b-form-group>
                                        <label for="email"
                                            >E-mail
                                            <small style="color: red"
                                                >*</small
                                            ></label
                                        >
                                        <b-form-input
                                            autocomplete="null"
                                            id="email"
                                            type="text"
                                            placeholder="Ej: ejemplo@email.com"
                                            v-model="$v.empresa.email.$model"
                                            :state="validateState('email')"
                                            :disabled="read_only"
                                        ></b-form-input>
                                        <div
                                            class="length-field mt-1"
                                            v-if="!$v.empresa.email.$anyError"
                                        >
                                            ({{
                                                $v.empresa.email.$model.length
                                            }}
                                            /
                                            {{
                                                $v.empresa.email.$params
                                                    .maxLength.max
                                            }})
                                        </div>
                                        <b-form-invalid-feedback
                                            v-if="!$v.empresa.email.maxLength"
                                        >
                                            Este campo debe tener máximo
                                            {{
                                                $v.empresa.email.$params
                                                    .maxLength.max
                                            }}
                                            caracteres
                                        </b-form-invalid-feedback>
                                        <b-form-invalid-feedback
                                            v-if="!$v.empresa.email.required"
                                        >
                                            Campo requerido
                                        </b-form-invalid-feedback>
                                        <b-form-invalid-feedback
                                            v-if="!$v.empresa.email.email"
                                        >
                                            E-mail inválido
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                            </b-col>
                            <b-col cols="12" md="4">
                                <b-form-group>
                                    <label for="direccion"
                                        >Dirección
                                        <small style="color: red"
                                            >*</small
                                        ></label
                                    >
                                    <b-form-input
                                        autocomplete="null"
                                        id="direccion"
                                        type="text"
                                        placeholder="Ej: calle 27 #38-43"
                                        v-model="$v.empresa.direccion.$model"
                                        :state="validateState('direccion')"
                                        :disabled="read_only"
                                    ></b-form-input>
                                    <div
                                        class="length-field mt-1"
                                        v-if="!$v.empresa.direccion.$anyError"
                                    >
                                        ({{
                                            $v.empresa.direccion.$model.length
                                        }}
                                        /
                                        {{
                                            $v.empresa.direccion.$params
                                                .maxLength.max
                                        }})
                                    </div>
                                    <b-form-invalid-feedback
                                        v-if="!$v.empresa.direccion.maxLength"
                                    >
                                        Este campo debe tener máximo
                                        {{
                                            $v.empresa.direccion.$params
                                                .maxLength.max
                                        }}
                                        caracteres
                                    </b-form-invalid-feedback>
                                    <b-form-invalid-feedback
                                        v-if="!$v.empresa.direccion.required"
                                    >
                                        Campo requerido
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" md="4" lg="4" xl="4">
                                <b-form-group>
                                    <label for="codigo_actividad_economica_id"
                                        >Actividad económica
                                        <small style="color: red"
                                            >*</small
                                        ></label
                                    >
                                    <jautocomplete
                                        id="codigo_actividad_economica_id"
                                        :items="codigos_actividad_economica"
                                        item-text="descripcion"
                                        item-value="id"
                                        @focus="onFocused"
                                        :custom-filter="
                                            filterCodigosActividadEconomica
                                        "
                                        open-on-focus
                                        v-model="
                                            $v.empresa
                                                .codigo_actividad_economica_id
                                                .$model
                                        "
                                        :state="
                                            validateState(
                                                'codigo_actividad_economica_id'
                                            )
                                        "
                                        :disabled="read_only"
                                    >
                                        <!-- @keyup.enter="goToNextField('pais_id')"
                                                @keyup.tab="goToNextField('pais_id')" -->
                                        <template v-slot:option="{ item }">
                                            <option>
                                                {{ item.codigo }} -
                                                {{ item.descripcion }}
                                            </option>
                                        </template>
                                    </jautocomplete>
                                    <div
                                        class="invalid-field mt-1"
                                        v-if="
                                            $v.empresa
                                                .codigo_actividad_economica_id
                                                .$error
                                        "
                                    >
                                        Campo requerido
                                    </div>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row class="mt-md-4">
                            <b-col cols="12" md="3" lg="3" xl="3">
                                <b-form-group>
                                    <label for="pais_id"
                                        >País
                                        <small style="color: red"
                                            >*</small
                                        ></label
                                    >
                                    <jautocomplete
                                        id="pais_id"
                                        @focus="onFocused"
                                        :items="paises"
                                        item-text="descripcion"
                                        item-value="id"
                                        :disabled="read_only"
                                        open-on-focus
                                        v-model="$v.empresa.pais_id.$model"
                                        @change="
                                            ;(municipios = []),
                                                (empresa.municipio_id = null),
                                                getMunicipios($event)
                                        "
                                        :state="validateState('pais_id')"
                                    />

                                    <!-- <b-form-select
                                        id="pais_id"
                                        text-field="descripcion"
                                        value-field="id"
                                        @focus="onFocused"
                                        :options="paises"
                                        v-model="$v.empresa.pais_id.$model"
                                        @change="
                                            ;(municipios = []),
                                                (empresa.municipio_id = null),
                                                getMunicipios($event)
                                        "
                                        :state="validateState('pais_id')"
                                        :disabled="read_only"
                                    >
                                        <template #first>
                                            <b-form-select-option
                                                :value="null"
                                                disabled
                                                >Seleccione</b-form-select-option
                                            >
                                        </template>
                                    </b-form-select> -->
                                    <div
                                        class="invalid-field mt-1"
                                        v-if="$v.empresa.pais_id.$error"
                                    >
                                        Campo requerido
                                    </div>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" md="3" lg="3" xl="3">
                                <b-form-group>
                                    <label for="municipio_id"
                                        >Ciudad
                                        <small style="color: red"
                                            >*</small
                                        ></label
                                    >
                                    <jautocomplete
                                        id="municipio_id"
                                        :items="municipios"
                                        item-text="descripcion"
                                        item-value="id"
                                        @focus="onFocused"
                                        open-on-focus
                                        :disabled="
                                            municipios.length === 0 || read_only
                                        "
                                        v-model="$v.empresa.municipio_id.$model"
                                        :state="validateState('municipio_id')"
                                    />
                                    <!-- @keyup.enter="
                                            goToNextField(
                                                'responsabilidades_fiscales_id'
                                            )
                                        "
                                        @keyup.tab="
                                            goToNextField(
                                                'responsabilidades_fiscales_id'
                                            )
                                        " -->
                                    <div
                                        class="invalid-field mt-1"
                                        v-if="$v.empresa.municipio_id.$error"
                                    >
                                        Campo requerido
                                    </div>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" md="6" lg="6" xl="6">
                                <b-form-group>
                                    <label for="responsabilidades_fiscales_id"
                                        >Responsabilidad físcal
                                        <small style="color: red"
                                            >*</small
                                        ></label
                                    >
                                    <jautocomplete
                                        id="responsabilidades_fiscales_id"
                                        :items="responsabilidades_fiscales"
                                        open-on-focus
                                        item-text="descripcion"
                                        item-value="id"
                                        multiple
                                        @focus="onFocused"
                                        v-model="
                                            $v.empresa
                                                .responsabilidades_fiscales_id
                                                .$model
                                        "
                                        :state="
                                            validateState(
                                                'responsabilidades_fiscales_id'
                                            )
                                        "
                                        :disabled="read_only"
                                    />
                                    <!-- @keyup.enter="
                                                goToNextField('retecree_id')
                                            "
                                            @keyup.tab="
                                                goToNextField('retecree_id')
                                            " -->
                                    <div
                                        class="invalid-field mt-1"
                                        v-if="
                                            $v.empresa
                                                .responsabilidades_fiscales_id
                                                .$error
                                        "
                                    >
                                        Campo requerido
                                    </div>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row class="mt-md-5">
                            <b-col cols="12">
                                <b-form-group>
                                    <label for="retecree_id"
                                        >Autoretención
                                        <small style="color: red"
                                            >*</small
                                        ></label
                                    >
                                    <jautocomplete
                                        id="retecree_id"
                                        :items="retecrees"
                                        item-text="descripcion"
                                        item-value="id"
                                        open-on-focus
                                        :disabled="
                                            retecrees.length === 0 || read_only
                                        "
                                        v-model="$v.empresa.retecree_id.$model"
                                        :state="validateState('retecree_id')"
                                    />
                                    <div
                                        class="invalid-field mt-1"
                                        v-if="$v.empresa.retecree_id.$error"
                                    >
                                        Campo requerido
                                    </div>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row class="mt-md-5">
                            <b-col cols="12" md="3" lg="3" xl="3">
                                <b-form-group>
                                    <label for="responsable_iva"
                                        >Reponsable IVA</label
                                    >
                                    <b-form-checkbox
                                        id="responsable_iva"
                                        size="lg"
                                        switch
                                        v-model="
                                            $v.empresa.responsable_iva.$model
                                        "
                                        :value="true"
                                        :unchecked-value="false"
                                        :disabled="read_only"
                                    >
                                    </b-form-checkbox>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" md="3" lg="3" xl="3">
                                <b-form-group>
                                    <label for="gran_contribuyente"
                                        >Gran contribuyente</label
                                    >
                                    <b-form-checkbox
                                        id="gran_contribuyente"
                                        size="lg"
                                        switch
                                        v-model="
                                            $v.empresa.gran_contribuyente.$model
                                        "
                                        :value="true"
                                        :unchecked-value="false"
                                        :disabled="read_only"
                                    >
                                    </b-form-checkbox>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" md="3" lg="3" xl="3">
                                <b-form-group>
                                    <label for="agente_retencion_fuente"
                                        >Agente de retención en la fuente</label
                                    >
                                    <b-form-checkbox
                                        id="agente_retencion_fuente"
                                        size="lg"
                                        switch
                                        v-model="
                                            $v.empresa.agente_retencion_fuente
                                                .$model
                                        "
                                        :value="true"
                                        :unchecked-value="false"
                                        :disabled="read_only"
                                    >
                                    </b-form-checkbox>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" md="3" lg="3" xl="3">
                                <b-form-group>
                                    <label for="agente_retencion_iva"
                                        >Agente de retención de iva</label
                                    >
                                    <b-form-checkbox
                                        id="agente_retencion_iva"
                                        size="lg"
                                        switch
                                        v-model="
                                            $v.empresa.agente_retencion_iva
                                                .$model
                                        "
                                        :value="true"
                                        :unchecked-value="false"
                                        :disabled="read_only"
                                    >
                                    </b-form-checkbox>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row class="mt-md-5">
                            <b-col cols="12" md="3" lg="3" xl="3">
                                <b-form-group>
                                    <label for="autoretenedor_fuente"
                                        >Autoretenedor fuente</label
                                    >
                                    <b-form-checkbox
                                        id="autoretenedor_fuente"
                                        size="lg"
                                        switch
                                        v-model="
                                            $v.empresa.autoretenedor_fuente
                                                .$model
                                        "
                                        :value="true"
                                        :unchecked-value="false"
                                        :disabled="read_only"
                                    >
                                    </b-form-checkbox>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" md="3" lg="3" xl="3">
                                <b-form-group>
                                    <label for="regimen_simple_tributacion"
                                        >Regimen simple de tributación</label
                                    >
                                    <b-form-checkbox
                                        id="regimen_simple_tributacion"
                                        size="lg"
                                        switch
                                        v-model="
                                            $v.empresa
                                                .regimen_simple_tributacion
                                                .$model
                                        "
                                        :value="true"
                                        :unchecked-value="false"
                                        :disabled="read_only"
                                    >
                                    </b-form-checkbox>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-form>
                </div>
            </b-card>
        </b-col>
        <b-col cols="12">
            <div class="d-flex flex-row float-right">
                <b-button
                    variant="secondary"
                    :disabled="isLoading"
                    v-b-popover.hover.top="'Presione esc'"
                    size="md"
                    class="mr-4"
                    @click="back()"
                >
                    Cancelar
                </b-button>

                <b-button
                    variant="primary"
                    :disabled="isLoading || read_only"
                    size="md"
                    v-b-popover.hover.top="
                        'Presione ctrl+shift+s o cmd+shift+s'
                    "
                    class="d-flex align-items-center"
                    @click="save"
                >
                    <feather
                        type="save"
                        size="1rem"
                        class="mr-2 text-blue-active"
                    />
                    <span
                        v-if="isLoading"
                        class="d-sm-flex align-items-center justify-content-center"
                    >
                        <b-spinner small></b-spinner>
                        <span class="ml-2">Guardando...</span>
                    </span>
                    <span v-else>Guardar</span>
                </b-button>
            </div>
        </b-col>
    </b-row>
</template>
<script>
import defaultImg from '@/assets/images/empresas-img.svg'
import services from '@/boot/axios'
import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength, email } from 'vuelidate/lib/validators'
import { getDV } from '@/utils/others'
import { mapGetters, mapMutations } from 'vuex'
import jautocomplete from '@/components/shared/autocomplete/jautocomplete'
import { filterCodigosActividadEconomica } from '@/utils/filters'
import {
    removeListenerCommands,
    addListenerCommands,
    goToNextField,
    onFocused,
    compareObjects
} from '@/utils/others'

const { API } = services

const defaultEmpresa = () =>
    JSON.parse(
        JSON.stringify({
            numero_documento: '',
            img: defaultImg,
            digito_verificacion: '0',
            razon_social: '',
            // representante_legal: '',
            retecree_id: 0,
            direccion: '',
            telefono: '',
            email: '',
            tipo_documento_id: null,
            agente_retencion_fuente: false,
            agente_retencion_iva: false,
            autoretenedor_fuente: false,
            gran_contribuyente: false,
            regimen_simple_tributacion: false,
            responsable_iva: false,
            // comercializadora_internacional: false,
            codigo_actividad_economica_id: null,
            tipo_empresa_id: null,
            pais_id: null,
            municipio_id: null,
            responsabilidades_fiscales_id: []
        })
    )

export default {
    name: 'CrearEmpresa',
    mixins: [validationMixin],
    components: { jautocomplete },
    data: () => ({
        defaultImg,
        empresaCopy: defaultEmpresa(),
        empresa: defaultEmpresa(),
        isLoading: false,
        isSaved: true,
        retecrees: [],
        codigos_actividad_economica: [],
        tipos_empresa: [],
        tipos_documento: [],
        municipios: [],
        paises: [],
        responsabilidades_fiscales: [],
        array: [],
        disableTipoDocumento: false,
        read_only: false
    }),
    watch: {
        empresa: {
            handler(newValue) {
                this.isSaved = compareObjects(this.empresaCopy, newValue)
            },
            deep: true
        }
    },
    mounted() {
        this.goToNextField('tipo_empresa_id')
        this.getParams()
        addListenerCommands(this)
        this.getTiposEmpresa()
        this.getTiposDocumento()
        this.getCodigosActividadEconomica()
        this.getPaises()
        this.getResponsabilidadesFiscales()
    },
    beforeDestroy() {
        removeListenerCommands(this)
    },
    beforeRouteLeave(to, from, next) {
        if (this.isSaved) {
            next()
        } else {
            this.$bvModal
                .msgBoxConfirm(
                    `
        Este formulario contiene información que no ha sido guardada,
        si continua con esta acción no se guardarán los cambios, desea continuar con esta acción?`,
                    {
                        title: 'Advertencia!',
                        size: 'sm',
                        buttonSize: 'sm',
                        okVariant: 'danger',
                        okTitle: 'Continuar',
                        cancelTitle: 'Cancelar',
                        footerClass: 'p-2 d-flex justify-content-between',
                        hideHeaderClose: false,
                        centered: true,
                        headerBgVariant: 'danger',
                        headerClass: 'modal-header'
                    }
                )
                .then((value) => {
                    if (value) {
                        next()
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    },
    validations: {
        empresa: {
            numero_documento: {
                required,
                minLength: minLength(4),
                maxLength: maxLength(20)
            },
            digito_verificacion: {
                required
            },
            razon_social: {
                required,
                maxLength: maxLength(70)
            },
            retecree_id: {
                required
            },
            direccion: {
                required,
                maxLength: maxLength(150)
            },
            telefono: {
                required,
                minLength: minLength(7),
                maxLength: maxLength(15)
            },
            email: {
                required,
                email,
                maxLength: maxLength(150)
            },
            codigo_actividad_economica_id: {
                required
            },
            tipo_empresa_id: {
                required
            },
            tipo_documento_id: {
                required
            },
            municipio_id: {
                required
            },
            pais_id: {
                required
            },
            agente_retencion_fuente: {},
            agente_retencion_iva: {},
            autoretenedor_fuente: {},
            gran_contribuyente: {},
            regimen_simple_tributacion: {},
            responsable_iva: {},
            responsabilidades_fiscales_id: {
                required
            }
        }
    },
    methods: {
        goToNextField,
        onFocused,
        filterCodigosActividadEconomica,
        showLog(data) {
            console.log(data) 
        },
        setTipoDocumento(id) {
            if (id === 2) {
                this.$v.empresa.tipo_documento_id.$model = '31'
                this.disableTipoDocumento = true
                // this.empresa.digito_verificacion = getDV(this.empresa.numero_documento)
            } else {
                this.$v.empresa.tipo_documento_id.$model = '13'
                // this.empresa.digito_verificacion = 'N/A'
                this.disableTipoDocumento = false
            }
        },
        setResponsabilidadesFiscales(data) {
            const index =
                this.$v.empresa.responsabilidades_fiscales_id.$model.indexOf(
                    data.id
                )
            if (index !== -1) {
                this.$v.empresa.responsabilidades_fiscales_id.$model.splice(
                    index,
                    1
                )
            } else {
                this.$v.empresa.responsabilidades_fiscales_id.$model.push(
                    data.id
                )
            }
        },
        getRetecrees(empresa_id) {
            API.POST({
                url: 'administracion/inventario/autoretencion/query',
                data: {
                    p_datajson: {
                        empresa_id
                    },
                    p_opc: 'GET_ALL',
                    p_auditoriajson: this.auditoriajson
                }
            })
                .then((response) => {
                    const {
                        data: {
                            dato,
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    if (status === 'ok') {
                        this.retecrees = dato
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        getTiposEmpresa() {
            API.POST({
                url: 'general/query',
                data: {
                    p_datajson: {},
                    p_opc: 'GET_ALL_TIPO_EMPRESA',
                    p_auditoriajson: this.auditoriajson
                }
            })
                .then((response) => {
                    const {
                        data: {
                            dato,
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    if (status === 'ok') {
                        this.tipos_empresa = dato
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        getTiposDocumento() {
            API.POST({
                url: 'general/query',
                data: {
                    p_datajson: {},
                    p_opc: 'GET_ALL_TIPO_DOCUMENTO',
                    p_auditoriajson: this.auditoriajson
                }
            })
                .then((response) => {
                    // console.log(response.data);
                    const {
                        data: {
                            dato,
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    if (status === 'ok') {
                        this.tipos_documento = dato
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        getCodigosActividadEconomica() {
            API.POST({
                url: 'general/query',
                data: {
                    p_datajson: {},
                    p_opc: 'GET_ALL_CODIGO_ACTIVIDAD_ECONOMICA',
                    p_auditoriajson: this.auditoriajson
                }
            })
                .then((response) => {
                    const {
                        data: {
                            dato,
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    // console.log(dato);
                    if (status === 'ok') {
                        this.codigos_actividad_economica = dato
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        getMunicipios(pais_id) {
            API.POST({
                url: 'general/municipio/query',
                data: {
                    p_datajson: {
                        pais_id
                    },
                    p_opc: 'GET_ALL',
                    p_auditoriajson: this.auditoriajson
                }
            })
                .then((response) => {
                    const {
                        data: {
                            dato,
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    // console.log(dato);
                    if (status === 'ok') {
                        this.municipios = dato
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        getPaises() {
            API.POST({
                url: 'general/pais/query',
                data: {
                    p_datajson: {},
                    p_opc: 'GET_ALL',
                    p_auditoriajson: this.auditoriajson
                }
            })
                .then((response) => {
                    const {
                        data: {
                            dato,
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    // console.log(dato);
                    if (status === 'ok') {
                        this.paises = dato
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        getResponsabilidadesFiscales() {
            API.POST({
                url: 'general/query',
                data: {
                    p_datajson: {},
                    p_opc: 'GET_ALL_RESPONSABILIDAD_FISCAL',
                    p_auditoriajson: this.auditoriajson
                }
            })
                .then((response) => {
                    const {
                        data: {
                            dato,
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    // console.log(dato);
                    if (status === 'ok') {
                        this.responsabilidades_fiscales = dato
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        getParams() {
            const { empresa_id } = this.$route.params
            if (empresa_id) {
                this.getEmpresaById(empresa_id)
                this.getRetecrees(empresa_id)
            } else {
                this.getRetecrees()
            }

            this.read_only = this.$route.meta.read_only
        },
        getEmpresaById(id) {
            // console.log('acá 2');
            API.POST({
                url: 'general/empresa/query',
                data: {
                    p_datajson: {
                        id
                    },
                    p_opc: 'FIND_BY_ID',
                    p_auditoriajson: this.auditoriajson
                }
            })
                .then((response) => {
                    const {
                        data: {
                            dato,
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    if (status === 'ok') {
                        this.empresaCopy = { ...dato }
                        this.empresa = { ...dato }
                        this.getMunicipios(this.empresa.pais_id)
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        setDV() {
            let DV = getDV(this.empresa.numero_documento)
            // if (this.$v.empresa.tipo_documento_id.$model === '31') {
            //   DV = getDV(this.empresa.numero_documento)
            // } else {
            //   DV = 'N/A'
            // }
            this.empresa.digito_verificacion = DV
        },
        openImgPicker() {
            this.$refs['empresa_img_input'].click()
        },
        onFileChanged(evt) {
            const file = evt.target.files[0]
            const reader = new FileReader()

            reader.onloadend = () => {
                this.empresa.img = reader.result
            }

            reader.readAsDataURL(file)
        },
        save() {
            // console.log(this.empresa)
            const valid = !this.$v.empresa.$invalid
            if (valid) {
                this.isLoading = true
                const p_opc = this.empresa.id ? 'UPDATE' : 'INSERT'
                API.POST({
                    url: 'general/empresa/crud',
                    data: {
                        p_datajson: {
                            ...this.empresa
                        },
                        p_opc,
                        p_auditoriajson: this.auditoriajson
                    }
                })
                    .then((response) => {
                        const {
                            data: {
                                dato,
                                status: { status, mensaje, mensaje_exception }
                            }
                        } = response
                        this.isLoading = false
                        if (status === 'ok') {
                            this.isSaved = true
                            if (dato.id === this.getUltimaEmpresa.id) {
                                this.setUltimaEmpresa(dato)
                            }
                            // this.empresa = defaultEmpresa()
                            this.back()
                        } else {
                            this.$bvModal
                                .msgBoxOk(
                                    mensaje ||
                                        mensaje_exception ||
                                        'Ha ocurrido un error!',
                                    {
                                        title: 'Error!',
                                        size: 'sm',
                                        buttonSize: 'sm',
                                        okVariant: 'error',
                                        okTitle: 'Aceptar',
                                        cancelTitle: 'Cancelar',
                                        footerClass: 'p-2',
                                        hideHeaderClose: false,
                                        centered: true,
                                        headerBgVariant: 'error',
                                        headerClass: 'modal-header'
                                    }
                                )
                                .then(() => {
                                    // console.log(value);
                                })
                                .catch((err) => {
                                    console.log(err)
                                })
                        }
                    })
                    .catch((error) => {
                        this.isLoading = false
                        const { message } = error
                        this.$bvModal
                            .msgBoxOk(message || 'Ha ocurrido un error!', {
                                title: 'Error!',
                                size: 'sm',
                                buttonSize: 'sm',
                                okVariant: 'error',
                                okTitle: 'Aceptar',
                                cancelTitle: 'Cancelar',
                                footerClass: 'p-2',
                                hideHeaderClose: false,
                                centered: true,
                                headerBgVariant: 'error',
                                headerClass: 'modal-header',
                                noStacking: true
                            })
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    })
            } else {
                this.$v.empresa.$touch()
            }
        },
        back() {
            this.$router.back()
        },
        validateState(key) {
            const { $dirty, $error } = this.$v.empresa[key]
            return $dirty ? !$error : null
        },
        ...mapMutations('empresas', ['setUltimaEmpresa'])
    },
    computed: {
        keymap() {
            if (
                navigator.platform.toLowerCase().includes('mac') ||
                navigator.platform.toLowerCase().includes('macintosh')
            ) {
                return {
                    'command+shift+s': this.read_only ? () => {} : this.save,
                    esc: () => this.$router.back()
                }
            } else if (
                navigator.platform.toLowerCase().includes('win') ||
                navigator.platform.toLowerCase().includes('windows')
            ) {
                return {
                    'ctrl+shift+s': this.read_only ? () => {} : this.save,
                    esc: () => this.$router.back()
                }
            } else {
                return {}
            }
        },
        auditoriajson() {
            const json = { ...this.getAuditoriaJson, modulo_id: 6 }
            return json
        },
        ...mapGetters('usuario', ['getUserData', 'getAuditoriaJson']),
        ...mapGetters('usuario', ['getUserData']),
        ...mapGetters('empresas', ['getUltimaEmpresa'])
    }
}
</script>
